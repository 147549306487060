/*===== TODO LIST ======*/
.todo-single-item {
  margin-bottom: 2.19em;
  position: relative;
  > span {
    margin-left: 1.56rem;
    line-height: 1.2;
    position: relative;
    display: inline-block;
    color: $black;
  }
  > span.badge {
    color: $white;
    @include transition(all 0.3s ease-in);
    display: inline-table;
  }
}
.todo-single-item i.mdi {
  width: 18px;
  height: 18px;
  border: 1px solid #d4d5db;
  border-radius: 50%;
  margin-right: 0.38rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  line-height: 1rem;
  position: absolute;
  left: 0;
  @include transition(all 0.3s ease-in);
  &:hover {
    border-color: rgba($primary, 0.5);
    background-color: rgba($primary, 0.2);
    cursor: pointer;
  }
}
.todo-input {
  display: none;
  input {
    margin-top: -0.31rem;
    border: none;
    font-size: 0.98rem;
    padding:  0.312rem 0.625rem;
    &:active,
    &:focus {
      box-shadow: 0px 2px 8px rgba($dark, 0.2);
    }
  }
}
.todo-single-item.finished {
  > span {
    color: #8a909d;
    text-decoration: line-through;
  }
  > span.badge {
    text-decoration: none;
    background-color: #cfd0d8;
    color: #8a909d;
  }
  i.mdi {
    background-color: #d4d5db;
    border-color: #d4d5db;
    &:after {
      content: "\f12c";
      font-size: .75rem;
      color: $secondary;
      font-family: $font-family-icon;
    }
  }
}
