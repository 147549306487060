/* Media Small */
.media-message {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  h4 {
    line-height: 1.5;
  }

  img {
    width: 40px;
  }

  .status {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 12px;
    height: 12px;

    border-radius: 50%;

    &.active {
      background-color: $success;
      border-color: $success;
    }

    &.away {
      background-color: $warning;
      border: 2px solid $white;
    }
  }

  .title {
    color: $dark;
    font-size: 0.9375rem;
    margin-bottom: 0;
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      width: 550px;
    }

    @include media-breakpoint-up(lg) {
      width: 125px;
    }

    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }

  .last-msg {
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
    color: $secondary;
    line-height: 1.5;

    @include media-breakpoint-up(md) {
      width: 550px;
    }

    @include media-breakpoint-up(lg) {
      width: 125px;
    }

    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }

  .date {
    text-align: right;
    font-size: 12px;
    color: $secondary;
  }

  &:hover {
    background-color: rgba($gray-200, .5);
    text-decoration: none;
  }

  &.media-active {
    background-color: $primary;

    .title,
    .last-msg,
    .date {
      color: $white;
    }

  }
}

#report-details {
  margin-bottom: 75px;

  @media(max-width: 768px) {
    margin-bottom: 20px;
  }
}

blockquote {

  &:nth-of-type(odd){
    border-left: 3px solid $primary;
    text-align: left;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -4px;
      height: 2em;
      background-color: $white;
      width: 5px;
      margin-top: -1em;
    }
  
    &:after {
      content: "\F027E";
      position: absolute;
      top: 50%;
      left: -0.5em;
      color: $primary;
      font-family: $font-family-matetial-icon;
      font-style: normal;
      line-height: 1em;
      text-align: center;
      text-indent: -2px;
      width: 1em;
      margin-top: -0.5em;
      transition: .2s all ease-in-out, .4s transform ease-in-out;
    }
  }

  &:nth-of-type(even){
    border-right: 3px solid $primary;
    text-align: right;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -4px;
      height: 2em;
      background-color: $white;
      width: 5px;
      margin-top: -1em;
    }
  
    &:after {
      content: "\F027E";
      position: absolute;
      top: 50%;
      right: -0.5em;
      color: $primary;
      font-family: $font-family-matetial-icon;
      font-style: normal;
      line-height: 1em;
      text-align: center;
      text-indent: -2px;
      width: 1em;
      margin-top: -0.5em;
      transition: .2s all ease-in-out, .4s transform ease-in-out;
    }
  }
  
  color: #495057;
  font-style: italic;
  line-height: 1.8em;
  padding: 1em 2em;
  position: relative;
  transition: .2s border ease-in-out;
  z-index: 0;
  
  

  &:active:after {
    transform: rotateY(360deg);
  }
}

cite {
  display: block;
  font-size: 0.75em;
  line-height: 1.8em;
  margin-top: 1em;
}

.card-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-content, .loaded-content {
  .is-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    min-height: 40px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.loaded-content .media:hover {

  .report-track-and-time {
    display: none;
  }

  .report-actions {
    display: flex;
  }
}

.report-track-and-time {
  display: block;
}

.report-actions {
  display: none;
  color: $primary;
  font-size: 1.8rem;

  &.secondary {
    color: $secondary;
  }

  &.success {
    color: $success
  }

  &.warning {
    color: $warning
  }

  &.info {
    color: $info
  }
 }

.report-actions i:hover {
  cursor: pointer;
}

.report-actions i {
  margin: auto 10px;

  @media(max-width: 512px) {
    margin: auto 5px;
  }
}

.static-result-actions {
  padding-top: 8px; 
  display: inline-block; 
  text-align: right; 
  overflow: hidden; 
  white-space: nowrap; 
  flex-direction: row; 
  display: flex; 
  flex: 0 0 100px; 
  justify-content: flex-end;
}

.report-track-and-time {
  @media(max-width: 512px) {
    display: none;
  }
}

.ofr-modal {
  margin-bottom: 20px;

  @media(max-width: 768px) {
    width: 100%!important;
  }
}

.ofr-card {
  @media(max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%; 
  }
}

.ofr-switch {
  margin-right: 3rem;

  @media(max-width: 512px) {
    margin-right: 0rem;
  }
}

.division-filter-dropdown {
  @media(max-width: 768px) {
    display: none!important;
  }
}

.media-chat {
  margin-bottom: 1.5rem;

  .content {
    background-color: rgba($gray-200, .5);
    padding: .5rem 1.25rem;
    border-radius: $border-radius;
    margin-bottom: 1.25rem;
  }

  .heading-title {
    a {
      color: $dark;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  .title {
    color: $primary;
    font-size: 1rem;
  }

  .message {
    background-color: rgba($gray-200, .5);
    padding: .5rem 1.25rem;
    border-radius: $border-radius;
    margin-bottom: .25rem;
    color: $dark;
  }

  .date-time {
    font-size: 10px;
    padding-left: 1.25rem;
  }

  .media-body {
    flex: unset;
  }

  &.media-left {
    img {
      width: 50px;
    }

  }

  &.media-right {
    text-align: right;
    justify-content: flex-end;

    img {
      width: 40px;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.media-chat-header {
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    padding: 1.125rem 1.5rem;

    @include media-breakpoint-up(lg) {
      border-top: 0;
    }

    img {
      width: 60px;
    }

    .dropdown-toggle {
      color: $secondary;
      text-transform: uppercase;

      &.icon-burger-mini {
        font-size: 1.5rem;

        &:after {
          display: none;
        }

        position: relative;

        &:before {
          font-family: $font-family-icon;
          content: "\F1D9";
        }
      }
    }

    .dropdown-menu {
      padding-top: .5rem;
      padding-bottom: .5rem;
      box-shadow: 0px 5px 18px 0px rgba(229, 233, 242, 0.5);
      min-width: auto;

      .dropdown-item {
        padding: 0.3125rem 1.25rem;

        a {
          font-size: 0.875rem;
          color: $secondary;
          text-transform: capitalize;
        }

        &:hover {
          background-color: transparent;

          a {
            color: $primary;
          }
        }
      }
    }
  }
}
