/* Github Icon */
.github-link {
  display: none;
  @include media-breakpoint-up(md){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    i {
      position: absolute;
      top: 2px;
      right: 7px;
      color: $white;
      font-size: 25px;
    }
  }
}

.header-fixed {
  .github-link {
    position: fixed;
    i {
      position: fixed;
    }
  }
}
