@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Nunito:wght@200;300;400&display=swap');

.bg-navy {
  background-color: $navy !important;
}

.sidebar-scrollbar::-webkit-scrollbar {
    display: none;
}

.sidebar-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.dropdown-menu-right {
    li:hover, a:hover {
      cursor: pointer;
    }
}

body.sidebar-minified {

  #open-create-division-modal-btn {
    i {
      display: block;
      font-weight: 500;
    }

    span {
      display: none;
    }

    padding: 0.59rem 0.59rem;
  }
  

  .division-switcher span {
    display: none;
  }
}

#open-create-division-modal-btn, .left-sidebar:hover #open-create-division-modal-btn {
  i {
    display: none;
  }

  span {
    display: block;
  }
}

.league-action {
  align-items: center;
  justify-content: center;

  button {
    font-family: "Roboto", sans-serif;

    border-radius: 25px;
    width: 100px;
    height: 40px;
    font-weight: 500;

    &.primary {
      background-color: $primary;
      color: white;
    }

    &.alt {
      background-color: white;
      color: $primary;    
    }
  }
}

.league-details {
  display: flex;
  flex-direction: row;
}

.joined-league {
  background-color: $primary;
  color: white;
  flex-direction: row;
  align-items: center;
  border: none;
  padding-right: 1.5rem;
  box-shadow: 0 8px 6px -10px $primary;

  h4 {
    border: none !important;
    margin: 0 !important;
    display: inline-block;
    flex-direction: column;
  }
}

.league-body {
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 400;
    margin-bottom: 15px;
    
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    span {
      border-bottom: 1px dashed #E0E0E0;
    }
  }

  p {
    margin-bottom: 20px;
    font-weight: 300;
  }

  .platforms {
    flex-direction: column;
    width: 100%;

    span {
      background-color: $gray-100;  
      padding: 5px 10px;
      border-radius: 10px;
      display: inline-block;
      margin: 2.5px 0px;
    }
  }
}

.create-league-btn {
  button {
    font-family: 'Nunito', sans-serif;
  }
}

button .subtitle {
    display: block;
    color: $gray-100;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.separator {
  display: flex;
  align-items: center;
  
  h2 {
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    text-transform: uppercase;
    padding: 0 2rem; /* creates the space */
  }
  
  .line{
    flex: 1;
    height: 1px;
    background-color: #E0E0E0;
  }
}

.card {
  &.joined {
    border-top: 3px solid $primary
  }

  overflow: hidden;
}

.single-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.division-game {
  display: flex;
  flex-direction: column;
  max-width: 40%;
}

.division-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 1.5rem;
}

.arrow-right {
  background-color: $primary;
  box-shadow: 0 8px 6px -10px ;
  box-shadow: 0 0 3px 2px #b3c6ff;
  height: 100px;
  right: -50px;
  position: absolute;
  top: -50px;
  width: 100px;
  
  -webkit-transform: rotate(45deg);

  span {
    color: #f5f5f5;
    font-family: sans-serif;
    font-size: 1.005em;
    left: 10px;
    top: 77px;
    position: absolute;
    width: 80px;
  }
}

.app-brand {
  font-size: 0.98rem;
  padding: 0 0rem!important;
  display: inline-block;
  line-height: 75px;
}

.division-create-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
    color: white;
    background-color: $primary;
  }

  button:hover {
    color: white;
  }
}

.division-switcher, .left-sidebar:hover .division-switcher {
  display: flex;
  flex-direction: column;
  background-color: #303745;
  border: 1px solid #3E404D;
  border-radius: 10px;
  box-shadow: 0 8px 6px -10px #000;
  transition: all .1s linear;

  height: auto;
  overflow: hidden;

  div {
    min-height: 60px;
    max-height: 60px;
    display: none;
    padding-left: 1.5rem;
    padding-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    align-items: start;
    flex-direction: column;
    justify-content: center;

    span {
      display: flex;
      flex-direction: column;
      color: #a6aab4;

      &.subtitle {
        font-size: 10px;
      }
    }

    button {
      font-family: "Roboto", sans-serif !important;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #8a909d;
      color: #303745;    
      border-radius: 25px;
      width: 60px;
      height: 20px;
      font-weight: 500;
      font-size: 12px;
    }

    &.active {
      display: flex;
      // border-bottom: 1px dashed #484954;
    }
  }

  div:nth-child(2) {
    border-top: 1px dashed #484954;
  }
}

.division-switcher:hover {
  cursor: pointer;
}

.division-switcher:hover, .left-sidebar:hover .division-switcher:hover {
  height: auto;
  max-height: 205px !important;
  overflow: scroll !important;

  div {
    
    &.active:hover {
        background-color: #303745;
    }

    &:hover {
      background-color:#212a39;

      
    }

    display: flex;

    

    // &:hover button {
    //   display: flex;
    // }
  }
}

// EVENTS-OVERVIEW

.event-card-image {
  overflow: hidden;
  position:relative;
}

.event-track-flag {
  position: relative;
  top: 70%;
  left: 40%;
  font-size: 30px;
}

.delete-btn {
  margin-right: auto;
}

#confirmation-modal {
  z-index: 1061
}

.event-dynamic-form-label {
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.terms-conditions-text {
  h4 {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  ol {
    margin-left: 40px;
  }

  ul li {
    list-style-type: disc;
  }

  ul {
    margin-left: 40px;
    list-style-type: disc;
    list-style-position:outside;
    display:list-item;

    li {
      font-family: "Nunito Sans", Arial, sans-serif;
      background: #fff;
      font-size: 14px;
      line-height: 1.8;
      font-weight: 400;
      color: #999999;
      margin-bottom: 0.5rem;
    }
  }

  p {
    font-family: "Nunito Sans", Arial, sans-serif;
    background: #fff;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;
    color: #999999;
    overflow-x: hidden;
    margin-bottom: 1rem;
  }
}