.select2-results__group {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: grey;
    color: white;
}

.select2-results__group:hover {
    cursor: pointer;
}