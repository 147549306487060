// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

// Colors
$primary:       #FF1801 !default;
$dark:          #1b223c !default;
$secondary:     #8a909d !default;
$ofr-grey:      #1c2127 !default;
$gray-100:      #f5f6fa !default;
$border-color:  #e5e9f2 !default;
$navy:          #212a39 !default;
$pink:          #ff548e !default;
$success:       #29cc97 !default;
$info:          #13cae1 !default;
$warning:       #fec400 !default;
$danger:        #fe5461 !default;
$inverse:       #070707 !default;
$purple:        #896def !default;
$white:         #ffffff !default;
$logo-fill-blue:#7DBCFF !default;

// Body colors

$body-color:    $secondary !default;

//Border radius

$border-radius: .25rem !default;

//Body
$body-bg:                      $gray-100; 

// Fonts:           ;

$font-family-sans-roboto:       'Roboto', sans-serif !default;
$font-family-matetial-icon:     'Material Design Icons' !default;
$font-family-base:              $font-family-sans-roboto !default;
$font-family-icon:              $font-family-matetial-icon !default;

$font-size-base:                .875rem !default;
$font-weight-medium:            500 !default;

//Heading

$headings-margin-bottom:        0 !default;
$h1-font-size:                  $font-size-base * 2.86 !default;
$h2-font-size:                  $font-size-base * 2.285 !default;
$h3-font-size:                  $font-size-base * 1.71 !default;
$h4-font-size:                  $font-size-base * 1.43 !default;
$h5-font-size:                  $font-size-base * 1.285 !default;
$h6-font-size:                  $font-size-base * 1.07 !default;

// Space
$spacer: 1rem;
$spacers: ();
$spacers:(
  6: ($spacer * 3.5),
  9: ($spacer * 5)
);

// Alerts

$alert-padding-y:               1.25rem !default;
$alert-padding-x:               1.25rem !default;
$alert-border-level:            -6 !default;

// Badges

$badge-padding-y:               .5em !default;
$badge-padding-x:               1em !default;
$badge-border-radius:           $border-radius / 2 !default;
$badge-font-weight:             $font-weight-medium !default;

// Breadcrumbs

$breadcrumb-padding-y:          1rem  !default;
$breadcrumb-padding-x:          1.25rem !default;
$breadcrumb-bg:                 transparent !default;

// Inputs

$input-btn-padding-y:         .59rem !default;
$input-btn-padding-x:         1rem !default;
$input-placeholder-color:     rgba($secondary, .7) !default;

$input-btn-padding-y-lg:      .75rem !default;

// Buttons

$btn-font-weight:             $font-weight-medium !default;

// Pagination

$pagination-padding-y:        1rem !default;
$pagination-padding-x:        1.25rem !default;

// Card
$card-border-color:           $border-color !default;

//Table
$table-dark-bg:               $navy;

//Modal
$modal-sm:                          400px !default;