@media (min-width: 1200px) {
  .col-xl-2-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.event-card {
  display: grid;
  grid-template-rows: 150px auto 50px;
  grid-template-areas: "image" "text" "stats";
  border-radius: 18px;
  background: white;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
  font-family: roboto;
  text-align: center;

  transition: 0.5s ease;
  cursor: pointer;

  padding: 0px !important;

  margin: 15px 0px;

  &:hover {
    cursor: unset;
  }

  &.finished {
    .date {
      color: $secondary;
    }

    .event-card-stats {
      background-color: $secondary;
    }
  }
}

.event-card-image {
  grid-area: image;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
}
.event-card-text {
  grid-area: text;
  margin: 25px;

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    font-size:20px;
    min-height: 65px;
    max-height: 65px;
  }

  .date {
    color: $primary;
    font-size:13px;
  }

  p {
    color: grey;
    font-size:15px;
    font-weight: 300;
    display: none;
  }
}
.event-card-stats {
  grid-area: stats; 
}

.event-card-stats {
  grid-area: stats; 
  display: grid;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: $primary;

  .stat {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }
}

//////////

@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

* {
	box-sizing: border-box;
}

.courses-container {
	
}

.course {
	background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: inherit;
  max-width: 100%;
  margin: 20px 0px;
  width: 700px;

  h6 {
    opacity: 0.6;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  h3 {
	  letter-spacing: 1px;
    margin-top:10px;
  }

  .course-preview {
    background-color: $navy;
    color: #fff;
    padding: 30px;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .course-preview a {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    opacity: 0.6;
    bottom: unset;
    right: 30px;
    position: absolute;
    top: 30px;
    position: absolute;
    text-decoration: none;

    @media (min-width: 1025px) {
      max-width: 100%;
    }
    overflow: hidden;
    max-width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .course-info {
    padding: 30px 30px 20px 30px;
    position: relative;
    width: 100%;

    h3 {
      letter-spacing: 1px;
      margin-bottom: 25px;
      min-height: 56px;
    }

    .view-btn {
      left: 15px;
      right: unset;
    }
  }

  .progress-container {
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    width: 50%;
  }

  .progress {
    background-color: #ddd;
    border-radius: 3px;
    height: 5px;
    width: 100%;
  }

  .progress::after {
    border-radius: 3px;
    background-color: $navy;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    height: 5px;
  }

  .progress::before {
    border-radius: 3px;
    background-color: darkgray;
    content: '';
    position: absolute;
    top: 0;
    right: 15px;
    height: 5px;
  }

  .progress-text {
    font-size: 10px;
    opacity: 0.6;
    letter-spacing: 1px;
  }

  .btn {
    background-color: #212a39;
    border: 0;
    border-radius: 50px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 16px;
    padding: 10px 15px;
    display: flex;
    margin-left: auto;
    letter-spacing: 1px;
    position: absolute;
    bottom: -40px;
    right: 15px;

    &.bg-red {
      background-color: $danger;
    }

    &.bg-green {
      background-color: $success
    }

    &.disabled {      
      &:hover {
        cursor: unset;
      }
    }
  }
}