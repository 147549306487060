.profile-banner-background {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
    border-radius: 5px;

    @media(max-width: 480px) {
        min-height: 120px;
    }
}

.profile-banner-picture {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: -50px;
    margin-left: 10%;
    float:left;

    @media(max-width: 480px) {
        height: 100px;
        width: 100px;
        margin-top: 15px;
        margin-left: 0%;
    }
}

.profile-banner-details {
    display: inline-block;
    margin-left: 1.5em;
    margin-top: 1em;

    @media(max-width: 480px) {
        margin-top: 3em;
    }

    h3 {
        color: #5B5B5A;
        margin-bottom: 0.25em;
    }
}