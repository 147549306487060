.search_box{
  
  background: #fff;
  border-radius: 5px;
  height: 65px;
  display: flex;
  padding: 10px;
  box-shadow: 0 8px 6px -10px #b3c6ff;
}

.search_box .dropdown{
  width: 150px;
  border-right: 2px solid #dde2f1;
  color: #9fa3b1;
  position: relative;
  cursor: pointer;
}

.search_box .dropdown .default_option{
  text-transform: uppercase;
  padding: 13px 15px;
  font-size: 14px;
}

.search_box .dropdown ul{
  position: absolute;
  top: 70px;
  left: -10px;
  background: #fff;
  width: 150px;
  border-radius: 5px;
  padding: 20px;
  display: none;
  box-shadow: 8px 8px 6px -10px #b3c6ff;
}

.search_box .dropdown ul.active{
  display: block;
  z-index: 99;
}

.search_box .dropdown ul li{
  padding-bottom: 20px;
}

.search_box .dropdown ul li:last-child{
  padding-bottom: 0;
}

.search_box .dropdown ul li:hover{
  color: #6f768d;
}

.search_box .dropdown:before{
  content: "";
  position: absolute;
  top: 18px;
  right: 20px;
  border: 8px solid;
  border-color: var(--primary) transparent transparent transparent;
}

.search_box .search_field{
  height: 100%;
  width: 80%;
  position: relative;
}

.search_box .search_field .input{
  width: 100%;
  height: 100%;
  border: 0px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 38px;
  color: #6f768d;
}

.search_box .search_field .mdi{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: $primary;
  cursor: pointer;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #9fa3b1;
}
::-moz-placeholder { /* Firefox 19+ */
 color: #9fa3b1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #9fa3b1;
}