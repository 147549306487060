/* Width */
.w-45 {
	width: 45px;
	height: auto;
}

/* Icon box width */
.iconbox-18 {
  width: 18px;
  height: 18px;
}
.iconbox-25 {
  width: 25px;
  height: 25px;
}
.iconbox-45 {
  width: 45px;
  height: 45px;
}

/* Vertical Height 100%*/
.vh-100 {
  min-height: 100vh;
}
