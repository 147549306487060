.team-header {
  position: relative !important;

  .team-details {

    .username {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .constructor {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media(max-width: 1024px) {
      margin-bottom: 1em;
    }
  }

  .team-car-details {
    flex: 0 0 66.6%;
    flex-direction: column;

    padding:0px;
    text-align: right !important;

    img {
      width: 90% !important;
      position: relative !important;
      top: unset;
      right: unset;
      left: unset !important;
    }
  }

  .team-car-details.f1 {
    img {
      width: 90%!important;
      position: relative!important;
      top: unset;
      right: unset;
      left: 0;
    }
  }
}


.results-header, .team-header {
  background-color: $navy;
  border-radius: 10px;
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px;

  .position {
    flex: 0 0 10%;
    flex-direction: column;

    img {
      width: 100px;
      position: absolute;
      top: 10px;
      left: 40px;
    }
  }

  .driver-details {
    flex: 0 0 20%;
    flex-direction: row;    
    font-size: 0.875rem;
    
    .username {
      color: #fff;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .team-driver-details {
    .username {
      color: #fff;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .top-3 {
    flex: 0 0 40%;
    flex-direction: row;    
    font-size: 0.875rem;
  }

  .car-details {
    flex: 0 0 33.3%;
    flex-direction: column;

    img {
      width: 535px;
      position: absolute;
      top: 30px;
      right: 20px;
    }

    img.acc {
      width: 435px;
      position: absolute;
      top: -30px;
      right: 20px;
    }
  }

  .team-car-details {
    img {
      width: 475px;
      position: absolute;
      top: unset;
      right: unset;
      left: 15%;
    }
  }

  .team-car-details.f1 {
    img {
      width: 535px;
      position: absolute;
      top: unset;
      right: unset;
      left: 0;
    }
  }
}

.team-banner-container {
  margin-bottom: 1.5rem;
}

@media(max-width: 1024px) {
  .car-details, .team-car-details {
    display: none;
  }

  .team-banner-container {
    margin-bottom: 1.5rem;
  }

  .top-3 {
    flex: 0 0 45% !important;
  }

  .driver-details {
    flex: 0 0 25% !important;
  }
}

@media(max-width: 768px) {
  .car-details, .team-car-details {
    display: none;
  }

  .top-3 {
    display: none;
  }

  .team-users {
    margin-top: 0.875rem;
  }

  .driver-details {
    flex: 0 0 70% !important;
  }
}

@media(max-width: 512px) {
  .car-details {
    display: none;
  }

  .top-3 {
    display: none;
  }

  .driver-details {
    flex: 0 0 60% !important;
  }
}

@media(max-width: 320px) {
  .car-details {
    display: none;
  }

  .top-3 {
    display: none;
  }

  .driver-details {
    flex: 0 0 50% !important;
  }
}

// EVENT NAVIGATION

.event-navigation span.active {
  cursor: pointer !important;
}

.event-navigation span.previous, .event-navigation span.next {
  width: 50px;
  cursor: not-allowed;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.event-navigation span.current {
  width: 100px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: unset;
}

// TABLE

.top-3 .leaderboard {
  border-collapse: collapse!important;
}

.leaderboard {
  position: relative;
  
  /* spacing to separate the table rows vertically */
  border-spacing: 0 1rem;
  border-collapse: unset;
}
/* with a pseudo element add a band with a repeating linear gradient */
.leaderboard:before {
  position: absolute;
  content: '';
  right: calc(100% + 1rem);
  top: 0;
  height: 100%;
  width: 1.5rem;
  border-radius: 5px;
}
/* center every element nested in a table row */
.leaderboard tr > * {
  text-align: center;
}
/* align every second item of each row to the left */
.leaderboard tr > *:nth-child(2) {
  text-align: left;
}

.race-leaderboard tr > *, #driver-leaderboard tr > * {
  padding: 0.5rem;
}

/* style the headings to have thin uppercase titles for the columns */
.race-leaderboard th, #driver-leaderboard th {
  font-weight: 300;
  letter-spacing: 0.04rem;
  font-size: 0.9rem;
  color: #fff;
  text-transform: uppercase;
}

/* slightly increase the weight of the cell describing the position */
.race-leaderboard td.position, #driver-leaderboard td.position {
  font-weight: 500;
  font-size: 0.9rem;
  color: white !important;
}

.race-leaderboard td.position, #race-leaderboard td.position span {
  min-width: 20px;
  display: inline-block;
}

.time-trial-entry > td {
  padding: 2.5px !important;
}

/* style the cell with the driver visually separating the first, last name and team */
.race-leaderboard td.driver, #driver-leaderboard td.driver {
  padding-left: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  min-width: 150px;
}

.color-white {
  color: #fff !important;
}

.color-dark {
  color: #15151E !important;
}

.race-leaderboard td.driver strong, #driver-leaderboard td.driver strong {
  text-transform: uppercase;
  font-weight: 500;
}

.race-leaderboard td.driver span, #driver-leaderboard td.driver span {
  text-transform: capitalize;
  font-size: 0.8rem;
  margin-top: 3px;
  margin-left: 10px;
  color: #999;
  letter-spacing: initial;
  display: inline-flex!important;
  min-width: 100px;
}

.race-leaderboard td.car, #driver-leaderboard td.car {
  padding: 0px;
  display: none;
}

.race-leaderboard td.car img, #driver-leaderboard td.car img {
  max-height: 50px;
}

@media (max-width: 768px) {
  .race-leaderboard td.driver span, #driver-leaderboard td.driver span {
    display: flex!important;
  }
}

@media(max-width: 320px) {
  .race-leaderboard td.driver, #driver-leaderboard td.driver {
    padding-left: 1rem;
    font-size: 0.7rem;
    letter-spacing: 0.05rem;
  } 
}

.race-leaderboard td.gap span, #driver-leaderboard td.gap span {
  background: #38383F;
  color: #ffffff;
  border-radius: 30px;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.race-leaderboard td.gap, #driver-leaderboard td.gap {
  text-align: right;
}

.leaderboard td.pts span {
  background: rgb(231, 231, 231);
  color: #38383F;
  border-radius: 30px;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
}

.leaderboard td.pts {
  width: 55px;
}

/* Qualify styles */

#qualify-leaderboard tr > *, #teams-leaderboard tr > * {
  padding: 0.35rem;
}

#qualify-leaderboard th, #teams-leaderboard th {
  font-weight: 300;
  letter-spacing: 0.04rem;
  font-size: 0.7rem;
  color: #fff;
  text-transform: uppercase;
}

#qualify-leaderboard td.position, #teams-leaderboard td.position {
  font-weight: 500;
}

#qualify-leaderboard td.driver, #teams-leaderboard td.driver {
  padding-left: 1rem;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
}

#qualify-leaderboard td.driver strong, #teams-leaderboard td.driver strong {
  text-transform: uppercase;
  font-weight: 500;
}

#qualify-leaderboard td.driver span, #teams-leaderboard td.driver span {
  text-transform: capitalize;
  margin-left: 0.75rem;
  font-size: 0.65rem;
  color: #999;
  display: inline-flex;
  min-width: 120px;
  letter-spacing: initial;
}

#qualify-leaderboard td.gap span, #teams-leaderboard td.gap span {
  background: #38383F;
  color: #ffffff;
  border-radius: 30px;
  padding: 0.5rem 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
}

/* on smaller viewports remove the third column from sight */
@media (max-width: 500px) {
  .race-leaderboard tr td.gap {
    display: none;
  }
}

#latest-race-result-track {
  float: left;
}

.clickable-username {
  border-bottom: 1px dashed grey;
}

.clickable-username:hover {
  border-bottom: 1px dashed #DC0000;
  cursor: pointer;
}

.race-leaderboard td.pts.fastest-lap span, #driver-leaderboard td.pts.fastest-lap span {
  background-color: #B138DF;
  color: white;
}

.scoreboard {
  border-top: 4px solid $primary;
  font-family: "Roboto", sans-serif;
  /* center the table horizontally */
  display: flex;
  flex-direction: column;

  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  padding: 0em 1em;
}


// HEADING

.heading-section .subheading {
  font-size: 16px;
  color: #999999;
  display: block;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Nunito Sans", Arial, sans-serif;
}

.event-navigation .heading-section .subheading {
  margin-left: 55px;
}

.heading-section h2 {
  font-size: 40px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Nunito Sans", Arial, sans-serif;
}