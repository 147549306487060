/* Padding Top */
.pt-25 {
	padding-top: 1.56rem;
}
.pt-30 {
	padding-top: 1.9rem;
}
.pt-35 {
	padding-top: 2.19rem;
}
.pt-40 {
	padding-top: 2.5rem;
}
.pt-45 {
	padding-top: 2.81rem;
}
.pt-55 {
	padding-top: 3.44rem;
}
.pt-50 {
	padding-top: 3.12rem;
}
.pt-60 {
	padding-top: 3.75rem;
}
.pt-65 {
	padding-top: 4.06rem;
}
.pt-70 {
	padding-top: 4.38rem;
}
.pt-75 {
	padding-top: 4.69rem;
}
.pt-80 {
	padding-top: 5rem;
}
.pt-90 {
	padding-top: 5.63rem;
}
.pt-100 {
  padding-top: 4.38rem;
  @include media-breakpoint-up(lg) {
    padding-top: 6.25rem;
  }
}

/* Padding Bottom */
.pb-25 {
	padding-bottom: 1.56rem;
}
.pb-30 {
	padding-bottom: 1.9rem;
}
.pb-35 {
	padding-bottom: 2.19rem;
}
.pb-40 {
	padding-bottom: 2.5rem;
}
.pb-45 {
	padding-bottom: 2.81rem;
}
.pb-50 {
	padding-bottom: 3.12rem;
}
.pb-55 {
	padding-bottom: 3.44rem;
}
.pb-60 {
	padding-bottom: 3.75rem;
}
.pb-65 {
	padding-bottom: 4.06rem;
}
.pb-70 {
	padding-bottom: 4.38rem;
}
.pb-75 {
	padding-bottom: 4.69rem;
}
.pb-80 {
	padding-bottom: 5rem;
}
.pb-90 {
	padding-bottom: 5.63rem;
}
.pb-100 {
	padding-bottom: 6.25rem;
}

.pv-35 {
	padding-top: 2.19rem;
	padding-bottom: 2.19rem;
}
.pv-40 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}
.pv-45 {
	padding-top: 2.81rem;
	padding-bottom: 2.81rem;
}
.pv-50 {
	padding-top: 3.12rem;
	padding-bottom: 3.12rem;
}
.pv-55 {
	padding-top: 3.44rem;
	padding-bottom: 3.44rem;
}
.pv-60 {
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
}
.pv-65 {
	padding-top: 4.06rem;
	padding-bottom: 4.06rem;
}
.pv-70 {
	padding-top: 4.38rem;
	padding-bottom: 4.38rem;
}
.pv-75 {
  padding-top: 4.69rem;
  padding-bottom: 4.69rem;
}
.pv-80 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.pv-90 {
	padding-top: 5.63rem;
	padding-bottom: 5.63rem;
}
.pv-100 {
	padding-top: 6.25rem;
	padding-bottom: 6.25rem;
}

/*  margin top */
.mt-30 {
	margin-top: 1.9rem;
}
.mt-35 {
	margin-top: 2.19rem;
}
.mt-40 {
	margin-top: 2.5rem;
}
.mt-50 {
	margin-top: 3.12rem;
}
.mt-60 {
	margin-top: 3.75rem;
}
.mt-70 {
	margin-top: 4.38rem;
}
.mt-75 {
	margin-top: 4.69rem;
}
.mt-80 {
	margin-top: 5rem;
}
.mt-85 {
	margin-top: 5.31rem;
}
.mt-90 {
	margin-top: 5.63rem;
}
.mt-95 {
	margin-top: 5.94rem;
}
.mt-100 {
  margin-top: 6.25rem;
}

/* Margin Bottom */
.mb-30 {
	margin-bottom: 1.9rem;
}
.mb-35 {
	margin-bottom: 2.19rem;
}
.mb-40 {
	margin-bottom: 2.5rem;
}
.mb-50 {
	margin-bottom: 3.12rem;
}
.mb-60 {
	margin-bottom: 3.75rem;
}
.mb-70 {
  margin-bottom: 4.38rem;
}
.mb-75 {
	margin-bottom: 4.69rem;
}
.mb-80 {
	margin-bottom: 5rem;
}
.mb-85 {
	margin-bottom: 5.31rem;
}
.mb-90 {
	margin-bottom: 5.63rem;
}
.mb-95 {
	margin-bottom: 5.94rem;
}
.mb-100 {
  margin-bottom: 6.25rem;
}

.min-height-vh83 {
	min-height: 83vh !important;
}
